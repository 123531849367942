<template>
  <v-container fluid class="grey lighten-3">

    <div align="center" class="mb-4">
      <v-img max-height="500" max-width="1200" style="border-radius: 15px;" src="@/assets/compado_mountains_1200x629.png"></v-img>
    </div>

    <v-card class="mb-1">
      <v-card-title>
        <v-spacer></v-spacer>
        Compado - Adtech user interface
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text align="center" justify="center">
        <p>If you have just logged in and can't see any options on the left, you may need to be assigned permissions. Please contact Anna D or your supervisor if you're a member of SEA, otherwise contact John M.
        </p>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'Home',
}
</script>
